import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import Avatar from "../components/Avatar";
import img1 from "../assets/img/img1.jpg";
import { AXIOS_METHOD_TYPES, API } from "../constants/api";
import { withAPIRequest } from "../HOC/withAPIRequest";
import CustomTooltip from "../components/CustomTooltip";
import { Link } from "react-router-dom";
import MemberTableplaceholder from "../components/placeholders/MemberTableplaceholder";
import Lottie from "lottie-react";
import noSearchAnimation from "../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { useDispatch, useSelector } from "react-redux";
import { FamilyAction } from "../store/slices/FamilySlice";
import AddFamilyMemberModal from "../modals/families/AddFamilyMemberModal";
import UpdateFamilyHeadModal from "../modals/families/UpdateFamilyHeadModal";
import Swal from "sweetalert2";
import maleImg from "../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../assets/img/women_delhi_diocese_theme.png";
import loadingAnimation from "../assets/lottie/loading_animation_delhi_theme.json";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const FamilyMembersTable = ({ commonAPIRequest, familyId, active }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { familyMembers } = useSelector((state) => state.family);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [updateFamilyHeadModalVisible, setUpdateFamilyHeadModalVisible] =
    useState(false);

  const getAllFamilyMembersDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY_MEMBERS}?familyId=${familyId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            FamilyAction.setCurrentFamilyMembers(
              result.payload.familyMemberDetails
                ? result.payload.familyMemberDetails
                : []
            )
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // function to call API for Delete a Member start

  const callDeleteMemberApi = (id) => {
    let serviceParams = {
      api: `${API.DELETE_MEMBER}?memberId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
          getAllFamilyMembersDetailsApi();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Delete a Member end

  useEffect(() => {
    if (familyId && active) {
      if (!familyMembers) {
        getAllFamilyMembersDetailsApi();
      }
    }
  }, [familyId, active]);

  return (
    <Card className="card-members">
      {/*  */}
      <Card.Body className="d-lg-none">
        <Row className="g-2 g-lg-3 mb-5">
          {!loading &&
            familyMembers?.map((member) => (
              <Col
                xs="12"
                md="6"
                xl="4"
                className="mb-4 shadow"
                key={member.memberId}
              >
                <Card
                  style={member?.dead ? { backgroundColor: "#e7e7e7" } : {}}
                  className="card-folder p-2"
                >
                  <Card.Body className="flex-column">
                    <img
                      style={{ maxWidth: 108 }}
                      src={member.gender === "Female" ? femaleImg : maleImg}
                      className="img-fluid"
                      alt="..."
                    />
                    <div style={{ position: "absolute", top: 20, right: 10 }}>
                      {member?.active ? (
                        <Badge
                          className="ms-1 fw-bold fs-6 text-white"
                          bg="success"
                          pill
                        >
                          Active
                        </Badge>
                      ) : (
                        <Badge
                          className="ms-1 fw-bold text-white fs-6"
                          bg="danger"
                          pill
                        >
                          Inactive
                        </Badge>
                      )}
                    </div>

                    {!member?.dead && (
                      <div style={{ position: "absolute", top: 10, left: 10 }}>
                        <Button
                          disabled={!member?.phNumber}
                          className="d-block mb-2"
                        >
                          <a href={`tel:+91${member?.phNumber}`}>
                            <i className=" text-white ri-phone-fill"></i>
                          </a>
                        </Button>

                        <Button
                          className="d-block mb-2"
                          disabled={!member?.emailAddress}
                        >
                          <a href={"mailto:" + member?.emailAddress}>
                            <i className="text-white ri-mail-fill"></i>
                          </a>
                        </Button>

                        <Link
                          to={"/admin/member-details"}
                          state={{
                            memberId: member.memberId,
                          }}
                        >
                          <Button
                            className="shadow-lg"
                            // style={{ position: "absolute", right: 20 }}
                          >
                            <i className="ri-user-3-line"></i>
                          </Button>
                        </Link>
                      </div>
                    )}

                    {member?.dead && (
                      <div style={{ position: "absolute", top: 50, left: 10 }}>
                        <Link
                          to={"/admin/member-details"}
                          state={{
                            memberId: member.memberId,
                          }}
                        >
                          <Button
                            className="shadow-lg"
                            // style={{ position: "absolute", right: 20 }}
                          >
                            <i className="ri-user-3-line"></i>
                          </Button>
                        </Link>
                      </div>
                    )}

                    {member?.dead && (
                      <Badge
                        style={{ position: "absolute", top: 20, left: 0 }}
                        bg="secondary text-white fw-bold fs-6 ms-2"
                        pill
                      >
                        Deceased
                      </Badge>
                    )}

                    <div className="card-content">
                      <h6 className="text-center">
                        <Link
                          href=""
                          className="link-02 fw-bold text-primary fs-20 mt-2"
                        >
                          {member.middleName
                            ? `${member.firstName} ${member.middleName} ${member.lastName}`
                            : `${member.firstName} ${member.lastName}`}
                        </Link>
                      </h6>
                      <span
                        style={{ fontSize: 14 }}
                        className="text-center mb-1 fw-bold"
                      >
                        {member?.familyHead
                          ? "Head Of Family"
                          : member?.relationToHead}
                      </span>
                      <Badge
                        className="ms-1 fw-bold text-white"
                        bg="primary"
                        pill
                      >
                        {!member?.dead
                          ? getAge(member?.dateOfBirth) + " Years Old"
                          : "-"}
                      </Badge>
                    </div>
                  </Card.Body>

                  <Card.Footer className=" pt-2 ps-2 bg-light">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span>Date Of Birth: </span>
                        <p className="text-primary fw-bold">
                          {member?.dateOfBirth
                            ? new Date(member?.dateOfBirth).toLocaleDateString(
                                "en-GB"
                              )
                            : "N/A"}
                        </p>
                      </div>
                      <div>
                        <span>BEC Unit: </span>
                        <p className="text-primary fw-bold">
                          {member.zoneName}
                        </p>
                      </div>
                    </div>
                    {/* <Link
                    to={"/admin/member-details"}
                    state={{
                      memberId: member.memberId,
                    }}
                  >
                    <Button
                      className="shadow-lg"
                      style={{ position: "absolute", right: 20 }}
                    >
                      <i className="ri-user-3-line"></i>
                    </Button>
                  </Link> */}
                  </Card.Footer>
                </Card>
              </Col>
            ))}
        </Row>

        {!loading && familyMembers?.length === 0 && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 180, height: 180 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
            <h3 className="text-center fw-bold">No Members Found</h3>
          </div>
        )}

        {loading && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 160, height: 160 }}
                animationData={loadingAnimation}
                loop={true}
              />
              <p
                style={{ marginLeft: -40 }}
                className="fw-bold text-center fs-18 mb-0"
              >
                Loading...
              </p>
            </div>
          </div>
        )}
      </Card.Body>
      {/*  */}
      <Card.Body className="d-none d-lg-inline">
        <div
          className="mb-3"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className="mx-2"
            variant="light"
            onClick={() => {
              getAllFamilyMembersDetailsApi();
            }}
            style={{ height: 40 }}
          >
            <i className="ri-refresh-line"></i>
          </Button>
          {(familyMembers?.find(
            (member) => member?.dead && member?.familyHead
          ) ||
            familyMembers?.find(
              (member) => member?.gender === "Female" && member?.familyHead
            )) && (
            <Button
              className="me-2"
              onClick={() => {
                setUpdateFamilyHeadModalVisible(true);
              }}
              variant="primary"
            >
              Update Family Head
            </Button>
          )}
          <Button
            onClick={() =>
              localStorage.getItem("currentFamilyStatus") === "true"
                ? setAddMemberModal(true)
                : // Swal.fire({
                  //     title: "Not Allowed",
                  //     text: "Inactive families cannot create new members.",
                  //     imageUrl:
                  //       "https://giphy.com/clips/minecraft-ice-age-mojang-minecraft-live-XpSKkMKOlwJ51jUfgE",
                  //     imageWidth: 400,
                  //     imageHeight: 200,
                  //     imageAlt: "Custom image",
                  //   })
                  Swal.fire(
                    "Not Allowed",
                    "Inactive families cannot create new members.",
                    "error"
                  )
            }
            variant="primary"
          >
            Add Member
          </Button>
        </div>
        <Table hover className="mb-0">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">D.O.B</th>
              <th scope="col">BEC Unit</th>
              <th scope="col">Relation To Head</th>
              <th scope="col">Details</th>
            </tr>
          </thead>
          {loading && <MemberTableplaceholder />}
          <tbody>
            {!loading &&
              familyMembers?.map((member, i) => (
                <tr key={member.memberId}>
                  <th scope="row">{i + 1}</th>
                  <td
                    style={member?.dead ? { backgroundColor: "#e7e7e7" } : {}}
                    className="d-flex align-items-center"
                  >
                    <Avatar
                      size="lg"
                      img={member.gender === "Female" ? femaleImg : maleImg}
                      // initial={`${member.firstName
                      //   .charAt(0)
                      //   .toUpperCase()} ${member.lastName
                      //   .charAt(0)
                      //   .toUpperCase()}`}
                    />
                    <div className="ms-3">
                      <div className="fw-bold">
                        {member.middleName
                          ? `${member.firstName} ${member.middleName} ${member.lastName}`
                          : `${member.firstName} ${member.lastName}`}
                        {member?.active ? (
                          <Badge className="ms-1 fw-bold" bg="success" pill>
                            Active
                          </Badge>
                        ) : (
                          <Badge className="ms-1 fw-bold" bg="danger" pill>
                            Inactive
                          </Badge>
                        )}
                        {member?.dead && (
                          <Badge bg="secondary ms-2">Deceased</Badge>
                        )}
                      </div>
                      <div className="fw-bolder text-primary">
                        {member?.emailAddress}
                      </div>
                    </div>
                  </td>
                  <td>
                    {new Date(member?.dateOfBirth).toLocaleDateString("en-GB")}
                    <br />
                    <span className="fw-bold text-primary">
                      {!member?.dead &&
                        getAge(member?.dateOfBirth) + " Years Old"}
                    </span>
                  </td>
                  <td>{member?.zoneName}</td>
                  <td>
                    {member?.familyHead
                      ? "Head Of Family"
                      : member?.relationToHead}
                  </td>
                  <td>
                    <Link
                      to={"/admin/member-details"}
                      state={{
                        memberId: member.memberId,
                      }}
                    >
                      <CustomTooltip
                        label={"View Member Detail"}
                        placement={"top"}
                      >
                        <Button>
                          <i className="ri-user-3-line"></i>
                        </Button>
                      </CustomTooltip>
                    </Link>

                    {/*  */}

                    {/* {!member?.familyHead && (
                      <CustomTooltip label={"Delete Member"} placement={"top"}>
                        <Button
                          onClick={() =>
                            Swal.fire({
                              title: "Are you sure?",
                              text: "You won't be able to revert this!",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes, delete it!",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                callDeleteMemberApi(member.memberId);
                              }
                            })
                          }
                          variant="danger"
                          className="ms-2"
                        >
                          <i className="ri-delete-bin-6-line"></i>
                        </Button>
                      </CustomTooltip>
                    )} */}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!loading && familyMembers?.length === 0 && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 180, height: 180 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
            <h3 className="text-center fw-bold">No Members Found</h3>
          </div>
        )}
      </Card.Body>
      {/* <Card.Footer>
        <pre className="text-end">
          <p className="text-muted">Last Updated by Akshay on 23/09/2023</p>
        </pre>
      </Card.Footer> */}
      <AddFamilyMemberModal
        setShow={setAddMemberModal}
        show={addMemberModal}
        familyId={familyId}
        getAllFamilyMembersDetailsApi={getAllFamilyMembersDetailsApi}
      />
      <UpdateFamilyHeadModal
        show={updateFamilyHeadModalVisible}
        setShow={setUpdateFamilyHeadModalVisible}
        getAllFamilyMembersDetailsApi={getAllFamilyMembersDetailsApi}
      />
    </Card>
  );
};

export default withAPIRequest(FamilyMembersTable);
