import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { useFormik } from "formik";
import {
  BaptismValidationStep1Schema,
  BaptismValidationStep2Schema,
  BaptismValidationStep3Schema,
  BaptismValidationStep4Schema,
} from "../../validations/baptism";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import BaptismFormStep1 from "../../forms/baptism/BaptismFormStep1";
import BaptismFormStep2 from "../../forms/baptism/BaptismFormStep2";
import BaptismFormStep3 from "../../forms/baptism/BaptismFormStep3";
import BaptismFormStep4 from "../../forms/baptism/BaptismFormStep4";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const MakeExtToIntBaptismModal = ({
  show,
  setShow,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  commonAPIRequest,
  currentBaptism,
  closeOffset,
}) => {
  const [currentState, setCurrentState] = useState(1);
  const [loading, setLoading] = useState(false);

  const formikStep1 = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      fatherName: "",
      motherName: "",
      dob: "",
      gender: "",
      dateOfBaptism: "",
      dobForValidation: "",
      rite: "",
      baptismDateNotSure: false,
    },
    validationSchema: BaptismValidationStep1Schema,
    onSubmit: (values) => {
      if (!(currentState >= 4)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formikStep2 = useFormik({
    initialValues: {
      fatherNationality: "Indian",
      fatherProfession: "",
      fatherDomicile: "",
      godFatherFirstName: "",
      godFatherLastName: "",
      godFatherDomicile: "",
      godMotherFirstName: "",
      godMotherLastName: "",
      godMotherDomicile: "",
    },
    validationSchema: BaptismValidationStep2Schema,
    onSubmit: (values) => {
      if (!(currentState >= 4)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formikStep3 = useFormik({
    initialValues: {
      registerRefNumber: null,
      baptismPlace: "",
      ministerName: "",
      remark: "",
    },
    validationSchema: BaptismValidationStep3Schema,
    onSubmit: (values) => {
      if (!(currentState >= 4)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formikStep4 = useFormik({
    initialValues: {
      dateOfBaptismForValidation: "",
      dateOfholyCommunion: "",
      dateOfholyConfirmation: "",
      dateOfMarriage: "",
    },
    validationSchema: BaptismValidationStep4Schema,
    onSubmit: () => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "warning",
        html: `
        <dl>
          <dt>Date Of Birth</dt>
          <dd>${new Date(formikStep1.values?.dob).toLocaleDateString("en-GB", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}</dd>
          <dt>Date Of Baptism</dt>
          <dd>${new Date(formikStep1.values?.dateOfBaptism).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
        </dl>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          callEditBaptismDetailsApi();
        }
      });
    },
  });

  // function to call API for Add New Baptism start

  const callEditBaptismDetailsApi = (value) => {
    let serviceParams = {
      api: API.EXTERNAL_BAPTISM_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        baptismDetail: {
          baptismId: currentBaptism?.baptismId,
          firstName: capitalizeText(formikStep1.values?.firstName.trim()),
          middleName: capitalizeText(formikStep1.values?.middleName.trim()),
          lastName: capitalizeText(formikStep1.values?.lastName.trim()),
          baptismDate: new Date(formikStep1.values.dateOfBaptism),
          dob: new Date(formikStep1.values.dob),
          gender: formikStep1.values?.gender,
          fatherName: capitalizeText(formikStep1.values?.fatherName.trim()),
          motherName: capitalizeText(formikStep1.values?.motherName.trim()),
          fatherNationality: capitalizeText(
            formikStep2.values?.fatherNationality.trim()
          ),
          fatherDomicile: capitalizeText(
            formikStep2.values?.fatherDomicile.trim()
          ),
          fatherProfession: capitalizeText(
            formikStep2.values?.fatherProfession.trim()
          ),
          godFatherName: capitalizeText(
            formikStep2.values?.godFatherFirstName.trim()
          ),
          godFatherSurName: capitalizeText(
            formikStep2.values?.godFatherLastName.trim()
          ),
          godFatherDomicile: capitalizeText(
            formikStep2.values?.godFatherDomicile.trim()
          ),
          godMotherName: capitalizeText(
            formikStep2.values?.godMotherFirstName.trim()
          ),
          godMotherSurName: capitalizeText(
            formikStep2.values?.godMotherLastName.trim()
          ),
          godMotherDomicile: capitalizeText(
            formikStep2.values?.godMotherDomicile.trim()
          ),
          placeOfBaptism: capitalizeText(
            formikStep3.values?.baptismPlace.trim()
          ),
          ministerName: capitalizeText(formikStep3.values?.ministerName.trim()),
          remarks: formikStep3.values?.remark.trim(),
          rite: formikStep1.values?.rite.trim(),
          regReferenceNum: formikStep3.values?.registerRefNumber
            ? +formikStep3.values?.registerRefNumber
            : null,
          baptismDateAccurate: !formikStep1.values.baptismDateNotSure,
          holyCommunionDate: new Date(formikStep4.values.dateOfholyCommunion),
          confirmationDate: new Date(formikStep4.values.dateOfholyConfirmation),
          marriageDate: new Date(formikStep4.values.dateOfMarriage),
          extBaptism: false,
          extBaptismChurch: null,
          extBaptismCity: null,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Baptism Created Successfully.`, "success");
          getFamilyMemberDetails();
          setShow(false);
          formikStep1.resetForm();
          formikStep2.resetForm();
          formikStep3.resetForm();
          closeOffset();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Baptism end

  useEffect(() => {
    if (show) {
      if (basicDetails?.baptismName) {
        formikStep1.setFieldValue("firstName", basicDetails?.baptismName);
        formikStep1.setFieldValue("middleName", "");
        formikStep1.setFieldValue("lastName", "");
      } else {
        formikStep1.setFieldValue("firstName", basicDetails?.firstName);
        formikStep1.setFieldValue("middleName", basicDetails?.middleName);
        formikStep1.setFieldValue("lastName", basicDetails?.lastName);
      }

      formikStep1.setFieldValue("fatherName", basicDetails?.fatherName);
      formikStep1.setFieldValue("motherName", basicDetails?.motherName);
      formikStep1.setFieldValue("dob", new Date(basicDetails?.dateOfBirth));
      formikStep1.setFieldValue(
        "dobForValidation",
        new Date(
          new Date(basicDetails?.dateOfBirth).setDate(
            new Date(basicDetails?.dateOfBirth).getDate() - 1
          )
        )
      );
      formikStep1.setFieldValue("gender", basicDetails?.gender);
      formikStep1.setFieldValue("rite", "Latin");
    }
  }, [show]);

  useEffect(() => {
    if (formikStep1.values.dateOfBaptism) {
      formikStep4.setFieldValue(
        "dateOfBaptismForValidation",
        new Date(formikStep1.values.dateOfBaptism)
      );
    }
  }, [formikStep1.values.dateOfBaptism]);

  return (
    <Modal
      backdrop="static"
      centered
      show={show}
      onHide={() => setShow(false)}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Make Baptism Certificate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 3
                      ? "current last"
                      : currentState > 3
                      ? "done last"
                      : "last"
                  }
                  aria-disabled="true"
                >
                  <a id="wizard1-t-2" href="#" aria-controls="wizard1-p-2">
                    <span className="number">3</span>{" "}
                    <span className="title">Step 3</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 4
                      ? "current last"
                      : currentState > 4
                      ? "done last"
                      : "last"
                  }
                  aria-disabled="true"
                >
                  <a id="wizard1-t-2" href="#" aria-controls="wizard1-p-2">
                    <span className="number">4</span>{" "}
                    <span className="title">Step 4</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <BaptismFormStep1
                    formik={formikStep1}
                    basicDetails={basicDetails}
                  />
                </div>
              )}
              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Additional Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <BaptismFormStep2 formik={formikStep2} />
                </div>
              )}

              {currentState === 3 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Additional Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <BaptismFormStep3 formik={formikStep3} />
                </div>
              )}

              {currentState === 4 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Add Some Important Dates
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <BaptismFormStep4 formik={formikStep4} />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (!(currentState <= 1)) {
                setCurrentState((prevValue) => prevValue - 1);
              }
            }}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (currentState === 1) {
                formikStep1.handleSubmit();
              } else if (currentState === 2) {
                formikStep2.handleSubmit();
              } else if (currentState === 3) {
                formikStep3.handleSubmit();
              } else if (currentState === 4) {
                formikStep4.handleSubmit();
              }
            }}
          >
            {currentState === 4 ? "Save" : "Next"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(MakeExtToIntBaptismModal);
